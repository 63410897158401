import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        pageTitle: 'Панель управления',
        breadcrumb: [
          {
            text: 'Панель управления',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/account/Account.vue'),
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        pageTitle: 'Аккаунт',
        breadcrumb: [
          {
            text: 'Аккаунт',
            active: true,
          },
        ],
      },
    },
    {
      path: '/site/brands-and-models',
      name: 'site_brands_and_models',
      component: () => import('@/views/site/brands/Brands.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Бренды и модели',
        breadcrumb: [
          {
            text: 'Бренды и модели',
            active: true,
          },
        ],
      },
    },
    {
      path: '/site/maintenance',
      name: 'maintenance',
      component: () => import('@/views/maintenance/Maintenance.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Техническое обслуживание',
        breadcrumb: [
          {
            text: 'Техническое обслуживание',
            active: true,
          },
        ],
      },
    },
    {
      path: '/site/brands-and-models/sort',
      name: 'site_brands_and_models_sort',
      component: () => import('@/views/site/brands/BrandsSort.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Сортировка Брендов',
        breadcrumb: [
          {
            text: 'Бренды и модели',
            to: {
              name: 'site_brands_and_models',
            },
          },
          {
            text: 'Сортировка брендов и моделей',
            active: true,
          },
        ],
      },
    },
    {
      path: '/site/services',
      name: 'site_services',
      component: () => import('@/views/site/services/Services.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Выбор услуг',
        breadcrumb: [
          {
            text: 'Услуги',
            active: true,
          },
        ],
      },
    },
    {
      path: '/promos',
      name: 'promos',
      component: () => import('@/views/promos/Promos.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Акции',
      },
    },
    {
      path: '/promos/sort',
      name: 'promos_sort',
      component: () => import('@/views/promos/PromosSort.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Сортировка Акций',
        breadcrumb: [
          {
            text: 'Акции',
            to: {
              name: 'promos',
            },
          },
          {
            text: 'Сортировка акций',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import('@/views/blog/Blog.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Блог',
      },
    },
    {
      path: '/blog/sort',
      name: 'blog_sort',
      component: () => import('@/views/blog/BlogSort.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Сортировка Публикаций',
        breadcrumb: [
          {
            text: 'Блог',
            to: {
              name: 'blog',
            },
          },
          {
            text: 'Сортировка публикаций',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reviews',
      name: 'reviews',
      component: () => import('@/views/reviews/Reviews.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Отзывы',
      },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('@/views/contacts/Contacts.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Контакты',
      },
    },
    {
      path: '/employees',
      name: 'employees',
      component: () => import('@/views/employees/Employees.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Сотрудники',
      },
    },
    {
      path: '/employees/sort',
      name: 'employees_sort',
      component: () => import('@/views/employees/EmployeesSort.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Сортировка сотрудников',
        breadcrumb: [
          {
            text: 'Сотрудники',
            to: {
              name: 'employees',
            },
          },
          {
            text: 'Сортировка сотрудников',
            active: true,
          },
        ],
      },
    },
    {
      path: '/vacancies',
      name: 'vacancies',
      component: () => import('@/views/vacancies/Vacancies.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Вакансии',
      },
    },
    {
      path: '/vacancies/sort',
      name: 'vacancies_sort',
      component: () => import('@/views/vacancies/VacanciesSort.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Сортировка вакансий',
        breadcrumb: [
          {
            text: 'Вакансии',
            to: {
              name: 'vacancies',
            },
          },
          {
            text: 'Сортировка ваканский',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contacts/sort',
      name: 'contacts_sort',
      component: () => import('@/views/contacts/ContactsSort.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Сортировка Контактов',
        breadcrumb: [
          {
            text: 'Контакты',
            to: {
              name: 'contacts',
            },
          },
          {
            text: 'Сортировка контактов',
            active: true,
          },
        ],
      },
    },
    {
      path: '/custom-page',
      name: 'custom-page',
      component: () => import('@/views/custom-page/CustomPage.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Пользовательские страницы',
      },
    },
    {
      path: '/work-counter',
      name: 'work-counter',
      component: () => import('@/views/work-counter/WorkCounter.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Счетчик услуг',
        breadcrumb: [
          {
            text: 'Счетчик услуг',
            active: true,
          },
        ],
      },
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('@/views/search/Search.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Поиск',
        breadcrumb: [
          {
            text: 'Поиск',
            active: true,
          },
        ],
      },
    },
    {
      path: '/requests',
      name: 'requests',
      component: () => import('@/views/requests/Requests.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Заявки',
      },
    },
    {
      path: '/analytics',
      name: 'analytics',
      component: () => import('@/views/analytics/Analytics.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Аналитика',
      },
    },
    {
      path: '/feedback/masters',
      name: 'feedback_masters',
      component: () => import('@/views/feedback/Masters.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Мастера',
      },
    },
    {
      path: '/feedback/stats',
      name: 'feedback_stats',
      component: () => import('@/views/feedback/Stats.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Статистика',
      },
    },
    {
      path: '/feedback/settings',
      name: 'feedback_settings',
      component: () => import('@/views/feedback/Settings.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Настройки',
      },
    },
    {
      path: '/site/services/sort',
      name: 'site_services_sort',
      component: () => import('@/views/site/services/ServicesSort.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Сортировка услуг',
        breadcrumb: [
          {
            text: 'Услуги',
            to: {
              name: 'site_services',
            },
          },
          {
            text: 'Сортировка услуг',
            active: true,
          },
        ],
      },
    },
    {
      path: '/templates/:templatesType',
      name: 'templates',
      component: () => import('@/views/templates/Templates.vue'),
      props: route => ({ templatesType: route.params.templatesType || 'services' }),
      meta: {
        pageTitle: 'Шаблоны',
        requires: {
          isAuth: true,
          isInit: true,
        },
      },
    },
    {
      path: '/pages',
      name: 'pages',
      component: () => import('@/views/pages/Pages.vue'),
      meta: {
        pageTitle: 'Услуги',
        requires: {
          isAuth: true,
          isInit: true,
        },
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/Settings.vue'),
      meta: {
        pageTitle: 'Общие настройки',
        requires: {
          isAuth: true,
          isInit: true,
        },
      },
    },
    {
      path: '/tokens',
      name: 'tokens',
      component: () => import('@/views/integrations/tokens/Tokens.vue'),
      meta: {
        pageTitle: 'Интеграции',
        requires: {
          isAuth: true,
          isInit: true,
        },
      },
    },
    {
      path: '/webhooks',
      name: 'webhooks',
      component: () => import('@/views/webhooks/Webhooks.vue'),
      meta: {
        requires: {
          isAuth: true,
        },
        pageTitle: 'Вебхуки',
        breadcrumb: [
          {
            text: 'Вебхуки',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/brands-and-models',
      name: 'admin_brands_and_models',
      component: () => import('@/views/admin/brands/Brands.vue'),
      meta: {
        requires: {
          isAdmin: true,
        },
        pageTitle: 'Бренды и модели',
        breadcrumb: [
          {
            text: 'Бренды и модели',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/services',
      name: 'admin_services',
      component: () => import('@/views/admin/services/Services.vue'),
      meta: {
        requires: {
          isAdmin: true,
        },
        pageTitle: 'Услуги',
        breadcrumb: [
          {
            text: 'Услуги',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/feedback-templates',
      name: 'admin_feedback_templates',
      component: () => import('@/views/admin/feedback/Templates.vue'),
      meta: {
        requires: {
          isAdmin: true,
        },
        pageTitle: 'Шаблоны сбора отзывов',
        breadcrumb: [
          {
            text: 'Шаблоны сбора отзывов',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-site',
      name: 'init',
      component: () => import('@/views/init/Init.vue'),
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isNotInit: true,
        },
      },
    },
    {
      path: '',
      component: () => import('@/layouts/auth/Auth.vue'),
      children: [
        {
          path: '/auth/login',
          name: 'login',
          component: () => import('@/views/auth/Login.vue'),
          meta: {
            requires: {
              isNotAuth: true,
            },
            layout: 'full',
          },
        },
        {
          path: '/auth/registration',
          name: 'registration',
          component: () => import('@/views/auth/Registration.vue'),
          meta: {
            requires: {
              isNotAuth: true,
            },
            layout: 'full',
          },
        },
        {
          path: '/auth/need-verify',
          name: 'need-verify',
          component: () => import('@/views/auth/NeedVerify.vue'),
          meta: {
            requires: {
              isAuth: true,
              isNotVerified: true,
            },
            layout: 'full',
          },
        },
        {
          path: '/auth/verify/:verify(.*)',
          name: 'verify',
          component: () => import('@/views/auth/Verify.vue'),
          meta: {
            requires: {
              isAuth: true,
            },
            layout: 'full',
          },
        },
        {
          path: '/auth/recovery',
          name: 'recovery',
          component: () => import('@/views/auth/Recovery.vue'),
          meta: {
            requires: {
              isNotAuth: true,
            },
            layout: 'full',
          },
        },
        {
          path: '/auth/password/reset',
          name: 'reset',
          component: () => import('@/views/auth/Reset.vue'),
          meta: {
            requires: {
              isNotAuth: true,
            },
            layout: 'full',
          },
        },
      ],
    },
    {
      path: '/error-500',
      name: 'error-500',
      component: () => import('@/views/error/Error500.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    // страницы с редиректами для страницы справочника на jaky.ru
    {
      path: '/help/start/main-info',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/start/main-info',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BD%D0%B0%D1%87%D0%B0%D0%BB%D0%BE-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B/%D0%B7%D0%B0%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%8B%D0%BC%D0%B8-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8'
      },
    },
    {
      path: '/help/start/brands-and-models',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/start/brands-and-models',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BD%D0%B0%D1%87%D0%B0%D0%BB%D0%BE-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B/%D0%B2%D1%8B%D0%B1%D0%BE%D1%80-%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%BE%D0%B2-%D0%B8-%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B5%D0%B9'
      },
    },
    {
      path: '/help/start/services',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/start/services',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BD%D0%B0%D1%87%D0%B0%D0%BB%D0%BE-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B/%D0%B2%D1%8B%D0%B1%D0%BE%D1%80-%D1%83%D1%81%D0%BB%D1%83%D0%B3'
      },
    },
    {
      path: '/help/start/brands-models-services-after-init',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/start/brands-models-services-after-init',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BD%D0%B0%D1%87%D0%B0%D0%BB%D0%BE-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B/%D0%B2%D1%8B%D0%B1%D0%BE%D1%80-%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%BE%D0%B2-%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B5%D0%B9-%D0%B8-%D1%83%D1%81%D0%BB%D1%83%D0%B3-%D0%BF%D0%BE%D1%81%D0%BB%D0%B5-%D0%B8%D0%BD%D0%B8%D1%86%D0%B8%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8'
      },
    },
    {
      path: '/help/start/reviews',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/start/reviews',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BD%D0%B0%D1%87%D0%B0%D0%BB%D0%BE-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B/%D0%B7%D0%B0%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D0%BE%D0%B2'
      },
    },
    {
      path: '/help/start/contacts',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/start/contacts',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BD%D0%B0%D1%87%D0%B0%D0%BB%D0%BE-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B/%D0%B7%D0%B0%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BA%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D0%BE%D0%B2'
      },
    },
    {
      path: '/help/settings/main',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/settings/main',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BE%D0%B1%D1%89%D0%B8%D0%B5-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B8'
      },
    },
    {
      path: '/help/settings/indexation-hide',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/settings/indexation-hide',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BE%D0%B1%D1%89%D0%B8%D0%B5-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B8/%D1%81%D0%BA%D1%80%D1%8B%D1%82%D0%B8%D0%B5-%D0%BE%D1%82-%D0%B8%D0%BD%D0%B4%D0%B5%D0%BA%D1%81%D0%B0%D1%86%D0%B8%D0%B8'
      },
    },
    {
      path: '/help/settings/breadcrumbs',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/settings/breadcrumbs',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BE%D0%B1%D1%89%D0%B8%D0%B5-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B8/%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0-%D1%85%D0%BB%D0%B5%D0%B1%D0%BD%D1%8B%D1%85-%D0%BA%D1%80%D0%BE%D1%88%D0%B5%D0%BA'
      },
    },
    {
      path: '/help/settings/scripts',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/settings/scripts',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%BE%D0%B1%D1%89%D0%B8%D0%B5-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B8/%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D0%B5-%D1%81%D0%BA%D1%80%D0%B8%D0%BF%D1%82%D0%BE%D0%B2'
      },
    },
    {
      path: '/help/templates/principals',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/templates/principals',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D1%88%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D1%8B'
      },
    },
    {
      path: '/help/templates/editing',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/templates/editing',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D1%88%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D1%8B/%D1%80%D0%B5%D0%B4%D0%B0%D0%BA%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D1%88%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D0%BE%D0%B2'
      },
    },
    {
      path: '/help/templates/variables',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/templates/variables',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D1%88%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D1%8B/%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%BF%D0%B5%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D1%8B%D1%85-%D0%B2-%D1%88%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D0%B0%D1%85'
      },
    },
    {
      path: '/help/templates/rotation',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/templates/rotation',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D1%88%D0%B0%D0%B1%D0%BB%D0%BE%D0%BD%D1%8B/%D1%80%D0%BE%D1%82%D0%B0%D1%86%D0%B8%D1%8F-%D0%BA%D0%BE%D0%BD%D1%82%D0%B5%D0%BD%D1%82%D0%B0'
      },
    },
    {
      path: '/help/services/editing',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/services/editing',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0-%D1%81%D0%BE-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B0%D0%BC%D0%B8-%D1%83%D1%81%D0%BB%D1%83%D0%B3'
      },
    },
    {
      path: '/help/services/prices',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/services/prices',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0-%D1%81%D0%BE-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B0%D0%BC%D0%B8-%D1%83%D1%81%D0%BB%D1%83%D0%B3/%D0%B7%D0%B0%D0%BF%D0%BE%D0%BB%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5-%D1%86%D0%B5%D0%BD-%D0%BD%D0%B0-%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8'
      },
    },
    {
      path: '/help/services/indexation',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/services/indexation',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0-%D1%81%D0%BE-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D0%B0%D0%BC%D0%B8-%D1%83%D1%81%D0%BB%D1%83%D0%B3/%D0%B8%D0%BD%D0%B4%D0%B5%D0%BA%D1%81%D0%B0%D1%86%D0%B8%D1%8F-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86'
      },
    },
    {
      path: '/help/requests/add-recipient',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/requests/add-recipient',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%B7%D0%B0%D1%8F%D0%B2%D0%BE%D0%BA'
      },
    },
    {
      path: '/help/requests/work-with-request',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/requests/work-with-request',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%B7%D0%B0%D1%8F%D0%B2%D0%BE%D0%BA/%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0-%D1%81-%D0%B7%D0%B0%D1%8F%D0%B2%D0%BA%D0%B0%D0%BC%D0%B8'
      },
    },
    {
      path: '/help/requests/history',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/requests/history',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%B4%D0%BE%D0%B1%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%B7%D0%B0%D1%8F%D0%B2%D0%BE%D0%BA/%D0%B8%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D0%B7%D0%B0%D1%8F%D0%B2%D0%BE%D0%BA-%D0%BF%D0%BE-%D0%BE%D0%B4%D0%BD%D0%BE%D0%BC%D1%83-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D1%83'
      },
    },
    {
      path: '/help/promos/show-promos-services',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/promos/show-promos-services',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%B2%D1%8B%D0%B2%D0%BE%D0%B4-%D0%B0%D0%BA%D1%86%D0%B8%D0%B9-%D0%BD%D0%B0-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83-%D1%83%D1%81%D0%BB%D1%83%D0%B3'
      },
    },
    {
      path: '/help/promos/show-promos-brands',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/promos/show-promos-brands',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%B2%D1%8B%D0%B2%D0%BE%D0%B4-%D0%B0%D0%BA%D1%86%D0%B8%D0%B9-%D0%BD%D0%B0-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83-%D1%83%D1%81%D0%BB%D1%83%D0%B3/%D0%B2%D1%8B%D0%B2%D0%BE%D0%B4-%D0%B0%D0%BA%D1%86%D0%B8%D0%B9-%D0%BD%D0%B0-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83-%D0%B1%D1%80%D0%B5%D0%BD%D0%B4%D0%B0'
      },
    },
    {
      path: '/help/promos/editing-promo',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/promos/editing-promo',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%B2%D1%8B%D0%B2%D0%BE%D0%B4-%D0%B0%D0%BA%D1%86%D0%B8%D0%B9-%D0%BD%D0%B0-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83-%D1%83%D1%81%D0%BB%D1%83%D0%B3/%D1%80%D0%B5%D0%B4%D0%B0%D0%BA%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B0%D0%BA%D1%86%D0%B8%D0%B9'
      },
    },
    {
      path: '/help/promos/editing-blog',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/promos/editing-blog',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%B2%D1%8B%D0%B2%D0%BE%D0%B4-%D0%B0%D0%BA%D1%86%D0%B8%D0%B9-%D0%BD%D0%B0-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86%D1%83-%D1%83%D1%81%D0%BB%D1%83%D0%B3/%D1%80%D0%B5%D0%B4%D0%B0%D0%BA%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B1%D0%BB%D0%BE%D0%B3%D0%B0'
      },
    },
    {
      path: '/help/location/promo-to-location',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/location/promo-to-location',
      },
      beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D0%BD%D0%B8%D0%B5-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B8%D1%86-%D0%BB%D0%BE%D0%BA%D0%B0%D1%86%D0%B8%D0%B8/%D0%BF%D1%80%D0%B8%D0%B2%D1%8F%D0%B7%D0%BA%D0%B0-%D0%B0%D0%BA%D1%86%D0%B8%D0%B8-%D0%BA-%D0%BB%D0%BE%D0%BA%D0%B0%D1%86%D0%B8%D0%B8'
      },
    },
    {
      path: '/help/integrations/integration-crm',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/integrations/integration-crm',
      },
      async beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://sites.google.com/view/jaky2/%D0%B8%D0%BD%D1%82%D0%B5%D0%B3%D1%80%D0%B0%D1%86%D0%B8%D1%8F-%D1%81-crm'
      },
    },
    {
      path: '/help/video',
      meta: {
        requires: {
          isAuth: true,
          isEmailVerified: true,
          isInit: true,
        },
        path: '/help/video',
      },
      async beforeEnter() {
        sessionStorage.removeItem('onLoginRedirect')
        window.location.href = 'https://www.youtube.com/watch?v=mCzx9fyGuyw'
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  const requires = to?.meta?.requires || {}
  const userIsAuth = store.getters['user/isAuth']
  const userIsAdmin = store.getters['user/isAdmin']
  const userIsVerifiedEmail = store.getters['user/isEmailVerified']
  const userIsInit = store.getters['user/hasSite'] && store.getters['user/siteStatus'] !== 'pending'
  // Если у роута есть meta path под редирект на справочник сохраняем его в localStorage

  if (to.meta.path) {
    sessionStorage.setItem('onLoginRedirect', to.meta.path)
  }

  if (requires.isAuth && !userIsAuth) {
    next({ name: 'login' })
  } else {
    next()
  }

  if (requires.isEmailVerified && !userIsVerifiedEmail) {
    next({ name: 'need-verify' })
  } else {
    next()
  }

  if (requires.isAdmin && !userIsAdmin) {
    next({ name: 'home' })
  } else {
    next()
  }

  if (requires.isInit && !userIsInit) {
    next({ name: 'init' })
  } else {
    next()
  }

  // todo: убрать этот редирект когда будет главная страница
  if (to.name === 'home') {
    const isRedirect = sessionStorage.getItem('onLoginRedirect')
    // проверяем существует ли редирект на справочник и если да, то делаем туда редирект после логина
    if (isRedirect && isRedirect !== null) {
      next({ path: isRedirect })
    } else {
      next({ name: 'pages' })
    }
  } else {
    next()
  }
  // ------

  if (requires.isNotVerified && userIsVerifiedEmail) {
    next({ name: 'home' })
  } else {
    next()
  }

  if (requires.isNotAuth && userIsAuth) {
    next({ name: 'home' })
  } else {
    next()
  }

  if (requires.isNotInit && userIsInit) {
    next({ name: 'home' })
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.classList.add('loading-fade-out')
    setTimeout(() => {
      appLoading.remove()
    }, 1000)
  }
})

export default router
