import HTTPService from '@/api/http/HTTPService'
import URLSearchParams from '@/@core/utils/getUrlParams'

import PostInterface from './PostInterface'
import PostSortInterface from './PostSortInterface'

export default {
  getAll: (siteSlug: string, query?: {page?: number, search?: string, is_published?: number}) => {
    const queryString = query ? URLSearchParams(query) : false
    return HTTPService.get(`site/${siteSlug}/blog${queryString ? `?${queryString}` : ''}`)
  },
  getAllSort: (siteSlug: string) => HTTPService.get(`site/${siteSlug}/blog/all`),
  add: (siteSlug: string, payload: PostInterface) => HTTPService.post(`site/${siteSlug}/blog`, payload),
  getOne: (siteSlug: string, postSlug: string) => HTTPService.get(`site/${siteSlug}/blog/${postSlug}`),
  update: (siteSlug: string, postSlug: string, payload: PostInterface) => HTTPService.put(`site/${siteSlug}/blog/${postSlug}`, payload),
  activate: (siteSlug: string, postSlug: string, isPublished: boolean) => HTTPService.patch(`site/${siteSlug}/blog/${postSlug}`, {
    is_published: isPublished,
  }),
  delete: (siteSlug: string, postSlug: string) => HTTPService.delete(`site/${siteSlug}/blog/${postSlug}`),
  sort: (siteSlug: string, payload: PostSortInterface) => HTTPService.post(`site/${siteSlug}/blog/reorder`, payload),
}
